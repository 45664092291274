import { gql } from '@biletiniz/apollo';

export const ALL_FLIGHT_OFFERS_QUERY = gql`
  query flightSearch(
    $flightSearchFilter: FlightSearchFilterInput
    $flightOfferFilter: FlightOfferFilterInput
    $session: String
  ) {
    flightSearch(session: $session, filter: $flightSearchFilter) {
      session
      sessionTTL
      flightOffersViewerMap
      metadata {
        directionsMetadata {
          departureMinDate
          departureMaxDate
          arrivalMinDate
          arrivalMaxDate
          elapsedMinTime
          elapsedMaxTime
        }
        airlines {
          code
          name
        }
        stopsNumber
        flightNumber
        transitAirports {
          code
          name
        }
      }
      flightOffers(filter: $flightOfferFilter) {
        id
        providerType
        providerContext
        isDirectionLevel
        isReservable
        defaultPackage
        remarks
        raw
        price {
          passengerFares {
            code
            quantity
            basePrice
            taxesPrice
            totalPrice
            serviceFeesPrice
          }
          currency
          basePrice
          serviceFeesPrice
          taxesPrice
          totalPrice
        }
        directions {
          providerContext
          elapsedTime
          defaultPackage
          price {
            passengerFares {
              code
              quantity
              basePrice
              taxesPrice
              totalPrice
              serviceFeesPrice
            }
            currency
            basePrice
            taxesPrice
            totalPrice
            serviceFeesPrice
          }
          packages {
            packageClass
            packageCode
            packageTitle
            prices {
              passengerFares {
                code
                quantity
                basePrice
                taxesPrice
                totalPrice
                serviceFeesPrice
              }
              packagePrice
              passengerQuantity
              passengerType
              currency
            }
            rules {
              sharedRules {
                text
                status
              }
              passengerRules {
                passengerType
                checkedBaggage {
                  quantity
                  pieces
                  unit
                }
                carryOnBaggage {
                  quantity
                  pieces
                  unit
                }
              }
            }
          }
          segments {
            elapsedTime
            flightNumber
            baggageUnit
            baggageQuantity
            cabinClass {
              code
              name
            }
            operatingAirline {
              code
              name
              logoUrl
            }
            airplane {
              code
              name
            }
            departureAirport {
              code
              name
            }
            arrivalAirport {
              code
              name
            }

            departureAt
            arrivalAt
          }
        }
      }
    }
  }
`;
