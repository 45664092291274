import { useState } from '@biletiniz/ui-core';
import { useMutation } from '@biletiniz/apollo';
import { range } from '@biletiniz/shared';
import {
  BOOK_FLIGHT_OFFER_MUTATION,
  RESERVE_FLIGHT_OFFER_MUTATION,
} from './gql';

const mapToBiletinizPassenger = (passenger, type) => ({
  passengerTypeCode: type,
  givenName: passenger.firstName,
  surname: passenger.lastName,
  gender: passenger.gender,
  hesCode: passenger.hesCode,
  passportNo: passenger.passportNo,
  tc: passenger.tc,
  mileNumber: passenger.mileNumber,
  birthDate: `${passenger.dateOfBirth.year}-${passenger.dateOfBirth.month}-${passenger.dateOfBirth.day}`,
});

const passengerInitialValues = userType => ({
  firstName: '',
  lastName: '',
  gender: 'MR',
  tcdegil: false,
  tc: '',
  passportNo: '',
  hesCode: '',
  mileNumber: '',
  userType,
  dateOfBirth: {
    day: '',
    month: '',
    year: '',
  },
});

export const useCheckoutFlight = ({
  session,
  flightOfferId,
  providerType,
  flightOffersSearchForm,
  pnrDetailsObj,
  packagesCode,
}) => {
  const [iframeUrl, setIframeUrl] = useState('');
  const [reservingData, setReservingData] = useState(null);

  const [
    bookingMutation,
    { loading: bookingLoading, error: bookingError },
  ] = useMutation(BOOK_FLIGHT_OFFER_MUTATION);
  const [
    reservationMutation,
    { loading: reservingLoading, error: reservingError },
  ] = useMutation(RESERVE_FLIGHT_OFFER_MUTATION);

  const booking = bookingInfo =>
    bookingMutation({
      variables: {
        flightOfferId,
        session,
        telephone: bookingInfo.isReservationPurchase
          ? pnrDetailsObj.pnrInformations.tel
          : bookingInfo.gsmNumber,
        email: bookingInfo.isReservationPurchase
          ? pnrDetailsObj.pnrInformations.email
          : bookingInfo.email,
        isReservationPurchase: bookingInfo.isReservationPurchase,
        pnr: bookingInfo.isReservationPurchase
          ? pnrDetailsObj.pnrInformations.code
          : '',
        providerType,
        passengers: bookingInfo.isReservationPurchase
          ? [
              {
                givenName: pnrDetailsObj.pnrInformations.firstName,
                surname: pnrDetailsObj.pnrInformations.lastName,
              },
            ]
          : [
              ...bookingInfo.adults.map(adult =>
                mapToBiletinizPassenger(adult, 'ADT')
              ),
              ...bookingInfo.childrens.map(child =>
                mapToBiletinizPassenger(child, 'CHD')
              ),
              ...bookingInfo.infants.map(infant =>
                mapToBiletinizPassenger(infant, 'INF')
              ),
            ],
        packagesCode: bookingInfo.packagesCode,
        paymentCard: {
          cardNumber: bookingInfo.cardNumber,
          expireYear: bookingInfo.expiryDate.split('/')[1],
          expireMonth: bookingInfo.expiryDate.split('/')[0],
          cvc: bookingInfo.cvc,
          cardHolderName: bookingInfo.cardHolderName,
        },
      },
    }).then(({ data }) => {
      const apiRootUrl = process.env.NEXT_PUBLIC_API_ROOT_URL || '';
      setIframeUrl(`${apiRootUrl}/payment-checkout/${data.flightOfferBooking}`);
    });

  const reserving = reservingInfo =>
    reservationMutation({
      variables: {
        flightOfferId,
        session,
        telephone: reservingInfo.gsmNumber,
        email: reservingInfo.email,
        packagesCode: reservingInfo.packagesCode,
        passengers: [
          ...reservingInfo.adults.map(adult =>
            mapToBiletinizPassenger(adult, 'ADT')
          ),
          ...reservingInfo.childrens.map(child =>
            mapToBiletinizPassenger(child, 'CHD')
          ),
          ...reservingInfo.infants.map(infant =>
            mapToBiletinizPassenger(infant, 'INF')
          ),
        ],
      },
    }).then(({ data }) => {
      setReservingData({
        pnr: data.flightOfferReserving.PNR,
        firstPassengerSurname: data.flightOfferReserving.surname,
        firstPassengerGivenName: data.flightOfferReserving.givenName,
        flightProviderType: data.flightOfferReserving.providerType,
      });
    });

  const { travellers } = flightOffersSearchForm.cabinClassTravellers;

  const formInitialValues = {
    adults: range(0, travellers.adultsCount).map(() =>
      passengerInitialValues('adult')
    ),
    childrens: range(0, travellers.childrenCount).map(() =>
      passengerInitialValues('child')
    ),
    infants: range(0, travellers.infantsCount).map(() =>
      passengerInitialValues('infant')
    ),
    email: '',
    gsmNumber: '',
    cardHolderName: '',
    cardNumber: '',
    cvc: '',
    packagesCode,
    termsOfService: false,
    testAdultsAges: false,
    expiryDate: '',
  };

  return {
    bookingFlightOfferError: bookingError?.message,
    reservingFlightOfferError: reservingError?.message,
    booking,
    reserving,
    bookingLoading,
    reservingLoading,
    reservingData,
    iframeUrl,
    setIframeUrl,
    formInitialValues,
  };
};
